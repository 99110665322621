var collage = function () {
    $('.collage').removeWhitespace().collagePlus({
        'fadeSpeed': 2000,
        'targetHeight': 300,
        'effect': 'effect-2',
        'allowPartialLastRow': true
    });
}

$(window).load(function () {
    $(document).ready(function () {
        $('.collage').show();
        collage();
    });

    $('.public-page').on('click', function () {
        var item = $(this);
        var url = item.attr('href');
        var modal = $("#publicPage");

        $.get(url, function (content) {
            modal.find('.modal-body').html(content);
            modal.find('.modal-title').text(item.data('title'));
            modal.modal('show');
        })
    });

    $("#toggle-full-menu").on('click', function (e) {

        e.preventDefault();

        var $menu = $("#full-menu");

        $(window).scrollTop(0);

        if($menu.is(':visible')){
            $menu.hide('slide', {
                direction: 'left'
            }, 500, function(){
                $(this).html('More <i class="fa fa-chevron-right"></i>');
            }.bind(this));
        } else {
            $menu.show('slide', {
                direction: 'left'
            }, 500, function(){
                $(this).html('Less <i class="fa fa-chevron-left"></i>');
            }.bind(this));
        }
    });


});

var resizeTimer = null;

$(window).bind('resize', function () {
    $('.collage .image-wrapper').css("opacity", 0);
    if (resizeTimer) clearTimeout(resizeTimer);
    resizeTimer = setTimeout(collage, 200);
});